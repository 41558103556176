import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { Link } from "gatsby";
import privacyPolicy from "../assets/images/accessibility.jpg";


const PrivacyPolicy = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Privacy Policy"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Privacy Policy"
      />
      <section className="privacy-policy-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="privacy-policy-content">
                <img src={privacyPolicy} alt="pp" />

                <section>
                  <h2>Accessibility Standards</h2>
                  <p>
                    Our website is designed to be accessible to all users,
                    including those with disabilities. We are committed to
                    ensuring that our website meets or exceeds recognized
                    accessibility standards.
                  </p>
                  <h3>1. Compliance with WCAG 2.1</h3>
                  <p>
                    We strive to meet the Web Content Accessibility Guidelines
                    (WCAG) 2.1 at the AA level. These guidelines help us ensure
                    that our content is accessible to users with various
                    disabilities, including visual, auditory, physical, speech,
                    cognitive, language, learning, and neurological
                    disabilities.
                  </p>
                  <h3>2. VPAT Compliance</h3>
                  <p>
                    We have completed a Voluntary Product Accessibility Template
                    (VPAT) to demonstrate how our website conforms to Section
                    508 standards for accessibility. The VPAT provides detailed
                    information on our website’s accessibility features and
                    compliance status.
                  </p>
                  <h3>3. Ongoing Accessibility Efforts</h3>
                  <p>
                    Our commitment to accessibility is ongoing. We continuously
                    review and improve our website based on user feedback and
                    accessibility audits. This includes:
                  </p>
                  <ul>
                    <li>
                      Regular testing with accessibility tools and user testing
                      with individuals who have disabilities.
                    </li>
                    <li>
                      Implementing feedback from accessibility audits and user
                      reports.
                    </li>
                    <li>
                      Providing training for our development team on
                      accessibility best practices.
                    </li>
                  </ul>
                  <h3>4. Accessibility Feedback</h3>
                  <p>
                    We welcome feedback on the accessibility of our website. If
                    you encounter any issues or have suggestions for
                    improvement, please contact us at{" "}
                    <a href="mailto:accessibility@example.com">
                      accessibility@example.com
                    </a>
                    .
                  </p>
                  <h3>5. Contact Us</h3>
                  <p>
                    For any questions regarding our Privacy Policy or
                    Accessibility Standards, please reach out to us at{" "}
                    <a href="mailto:support@example.com">support@example.com</a>
                    .
                  </p>
                </section>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <aside className="widget-area">
                <div className="widget widget_insight">
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    {/* <li>
                      <Link to="#">Purchase Guide</Link>
                    </li> */}
                    <li >
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li className="active">
                      <Link to="/accessibility">Accessibility</Link>
                    </li>
                  </ul>
                </div>

                <div className="widget widget_recent_courses">
                  <h3 className="widget-title">Recent Courses</h3>

                  <article className="item">
                    <Link to="#" className="thumb">
                      <span className="fullimage cover bg1" role="img"></span>
                    </Link>
                    <div className="info">
                      <span>$49.00</span>
                      <h4 className="title usmall">
                        <Link to="#">
                          The Data Science Course 2020: Complete Data Science
                          Bootcamp
                        </Link>
                      </h4>
                    </div>
                    <div className="clear"></div>
                  </article>

                  <article className="item">
                    <Link to="#" className="thumb">
                      <span className="fullimage cover bg2" role="img"></span>
                    </Link>
                    <div className="info">
                      <span>$59.00</span>
                      <h4 className="title usmall">
                        <Link to="#">
                          Java Programming MasterclassName for Software
                          Developers
                        </Link>
                      </h4>
                    </div>
                    <div className="clear"></div>
                  </article>

                  <article className="item">
                    <Link to="#" className="thumb">
                      <span className="fullimage cover bg3" role="img"></span>
                    </Link>
                    <div className="info">
                      <span>$69.00</span>
                      <h4 className="title usmall">
                        <Link to="#">
                          Deep Learning A-Z™: Hands-On Artificial Neural
                          Networks
                        </Link>
                      </h4>
                    </div>
                    <div className="clear"></div>
                  </article>
                </div>

                <div className="widget widget_tag_cloud">
                  <h3 className="widget-title">Popular Tags</h3>

                  <div className="tagcloud">
                    <Link to="#">
                      Business <span className="tag-link-count"> (3)</span>
                    </Link>
                    <Link to="#">
                      Design <span className="tag-link-count"> (3)</span>
                    </Link>
                    <Link to="#">
                      Braike <span className="tag-link-count"> (2)</span>
                    </Link>
                    <Link to="#">
                      Fashion <span className="tag-link-count"> (2)</span>
                    </Link>
                    <Link to="#">
                      Travel <span className="tag-link-count"> (1)</span>
                    </Link>
                    <Link to="#">
                      Smart <span className="tag-link-count"> (1)</span>
                    </Link>
                    <Link to="#">
                      Marketing <span className="tag-link-count"> (1)</span>
                    </Link>
                    <Link to="#">
                      Tips <span className="tag-link-count"> (2)</span>
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;
